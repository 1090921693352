import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalAdicionarMeses = ({
  modalAdicionarMeses,
  setModalAdicionarMeses,
  setRefresh,
  teste,
}) => {
  const [loading, setLoading] = useState(false);
  const [mesesAdicionar, setMesesAdicionar] = useState("1");

  const renovar = async (testeId) => {
    setLoading(true);

    let response = await apiClient.put(
      `/testes/${testeId}/${mesesAdicionar}/migrar-cliente`,
      {}
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setMesesAdicionar(0);
      setModalAdicionarMeses(!modalAdicionarMeses);
    } else {
      response = await apiClient.put(
        `/testes/${testeId}/${mesesAdicionar}/migrar-cliente`,
        {}
      );
      if (response.sucesso) {
        toast.success(response.mensagem);
        localStorage.removeItem("lastUpdated");
        setRefresh(true);
        setMesesAdicionar(0);
        setModalAdicionarMeses(!modalAdicionarMeses);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401")) {
          if (response.mensagem !== "") toast.error(response.mensagem);
        } else {
          await checkLastLogin();
        }
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    if (modalAdicionarMeses && teste) {
      setMesesAdicionar(1);
    }
  }, [modalAdicionarMeses]);
  return (
    <Modal
      isOpen={modalAdicionarMeses}
      toggle={() => {
        setModalAdicionarMeses(!modalAdicionarMeses);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalAdicionarMeses(!modalAdicionarMeses);
        }}
      >
        Migrar {teste?.username} para Cliente
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="meses" className="form-label">
                  Meses a Adicionar
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="meses"
                  value={mesesAdicionar}
                  onChange={(e) =>
                    setMesesAdicionar(e.target.value.replace(/\D/g, ""))
                  }
                  maxLength={2}
                />
                <small className="text-muted">
                  Créditos a serem gastos:{" "}
                  {(
                    (mesesAdicionar || 0) * (teste?.painel_tipo === 1 ? 0.8 : 1)
                  ).toFixed(2)}{" "}
                  créditos
                </small>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => setModalAdicionarMeses(!modalAdicionarMeses)}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-success"
              onClick={() => renovar(teste?.id)}
              disabled={loading}
            >
              Migrar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalAdicionarMeses;
