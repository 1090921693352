import React from "react";
import {
  Col,
  Row,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";

const Legendas = ({userDados}) => {
  return (
    <Row>
      <b>Legendas dos botões</b>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon icon="mail" width="13" className="me-3 text-success" />
          Enviar Email de Suporte
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon icon="users" width="13" className="me-3 text-primary" />
          Ver Clientes
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon icon="users" width="13" className="me-3 text-warning" />
          Ver Revendedores
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon icon="edit" width="13" className="me-3 text-secondary" />
          Editar
        </small>
      </Col>
      {userDados?.data?.id === 1 && (
        <Col xl={2}>
          <small className="text-muted text-left mb-2">
            <FeatherIcon icon="user" width="13" className="me-3 text-primary" />
            Mudar Master
          </small>
        </Col>
      )}
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon
            icon="plus-circle"
            width="13"
            className="me-3 text-success"
          />
          Adicionar Créditos
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon
            icon="minus-circle"
            width="13"
            className="me-3 text-danger"
          />
          Remover Créditos
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon icon="key" width="13" className="me-3 text-warning" />
          Mudar Senha
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon icon="lock" width="13" className="me-3 text-info" />
          Bloquear / Desbloquear
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon icon="user-x" width="13" className="me-3 text-danger" />
          Bloquear / Desbloquear Clientes
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon icon="file" width="13" className="me-3 text-warning" />
          Logs do Revenda
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon icon="trash" width="13" className="me-3 text-danger" />
          Apagar
        </small>
      </Col>
    </Row>
  );
};

export default Legendas;
