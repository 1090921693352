import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import { useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import SearchOption from "./SearchOption";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const Logs = () => {
  document.title = "Logs de Atividade | Dark Store";
  const { user_id } = useParams();

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [logs, setLogs] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalDeLogs, setTotalDeLogs] = useState(0);
  const [numeroDePaginas, setNumeroDePaginas] = useState(1);
  const [termoBusca, setTermoBusca] = useState("");

  const calcularPaginas = () => {
    const paginas = [];
    const paginasVisiveis = 2;

    // Adicionar as três primeiras páginas
    for (let i = 1; i <= Math.min(paginasVisiveis, numeroDePaginas); i++) {
      paginas.push(i);
    }

    let inicio = Math.max(paginaAtual - paginasVisiveis, paginasVisiveis + 1);
    let fim = Math.min(
      paginaAtual + paginasVisiveis,
      numeroDePaginas - paginasVisiveis
    );

    if (inicio > paginas[paginas.length - 1] + 1) {
      paginas.push("...");
    }

    for (let i = inicio; i <= fim; i++) {
      paginas.push(i);
    }

    if (fim < numeroDePaginas - paginasVisiveis) {
      paginas.push("...");
    }

    // Adicionar as três últimas páginas
    for (
      let i = Math.max(
        numeroDePaginas - paginasVisiveis + 1,
        paginasVisiveis + 1
      );
      i <= numeroDePaginas;
      i++
    ) {
      if (!paginas.includes(i)) {
        paginas.push(i);
      }
    }

    return paginas;
  };

  const converterData = (dataISO) => {
    if (dataISO) {
      const ano = dataISO.substring(0, 4);
      const mes = dataISO.substring(5, 7);
      const dia = dataISO.substring(8, 10);
      const hora = dataISO.substring(11, 13);
      const minuto = dataISO.substring(14, 16);

      return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
    }
    return "";
  };

  const fetchLogs = useCallback(
    async (itensPorPagina, paginaAtual, termoBusca) => {
      setLoading(true);

      const userData = JSON.parse(localStorage.getItem("authUser"));
      const userId = user_id ? user_id : userData?.data?.id;

      const response = await apiClient.post(`logs/${userId}/buscar`, {
        itens_pagina: itensPorPagina,
        pagina: termoBusca === "" ? paginaAtual : 1,
        termo: termoBusca,
      });

      if (response.sucesso) {
        const dados = response.dados;
        setLogs(dados.data);
        setNumeroDePaginas(dados.last_page);
        setTotalDeLogs(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401")) {
          if (response.mensagem !== "") toast.error(response.mensagem);
        } else {
          await checkLastLogin();
        }
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }

      setLoading(false);
    },
    []
  );

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadTable(true);
      await fetchLogs(itensPorPagina, paginaAtual, termoBusca);
      if (refresh) {
        await refreshUser();
        setRefresh(false);
      }
      setLoadTable(false);
    };

    fetchData();
    setLoading(false);
  }, [loading, itensPorPagina, paginaAtual, termoBusca, refresh]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Logs" pageTitle="Utilitários" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2">
              <Col xl={3}>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setLoading(true);
                  }}
                  value={itensPorPagina}
                >
                  <option>Itens por Página</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              <Col xl={9}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                />
              </Col>
            </Row>
          </Card>
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Logs de Atividade
                  </h4>
                </CardHeader>
                <CardBody>
                  {!loading && !loadTable && logs.length === 0 ? null : (
                    <div className="align-items-center mb-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeLogs
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeLogs
                            )}
                          </span>{" "}
                          de <span className="fw-semibold">{totalDeLogs}</span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                          <li
                            className={`page-item ${
                              paginaAtual === 1 ? "disabled" : ""
                            }`}
                            style={{ width: "34px", height: "35px" }}
                          >
                            <Link
                              to="#"
                              className="page-link p-0"
                              onClick={() => {
                                setPaginaAtual(paginaAtual - 1);
                                setLoading(true);
                              }}
                            >
                              <FeatherIcon icon="chevron-left" width="16" />
                            </Link>
                          </li>
                          {calcularPaginas(paginaAtual, numeroDePaginas).map(
                            (pagina, i) =>
                              pagina === "..." ? (
                                <li
                                  key={i}
                                  className="page-item disabled"
                                  style={{ userSelect: "none" }}
                                >
                                  <span className="page-link">{pagina}</span>
                                </li>
                              ) : (
                                <li
                                  key={i}
                                  className={`page-item ${
                                    pagina === paginaAtual ? "active" : ""
                                  }`}
                                  style={{ height: "35px" }}
                                >
                                  <Link
                                    to="#"
                                    className="page-link pb-1 pt-1 pe-2 ps-2"
                                    onClick={() => {
                                      setPaginaAtual(pagina);
                                      setLoading(true);
                                    }}
                                    style={{ fontSize: "0.7rem" }}
                                  >
                                    {pagina}
                                  </Link>
                                </li>
                              )
                          )}
                          <li
                            className={`page-item ${
                              paginaAtual === numeroDePaginas ? "disabled" : ""
                            }`}
                            style={{ width: "34px", height: "35px" }}
                          >
                            <Link
                              to="#"
                              className="page-link p-0"
                              onClick={() => {
                                setPaginaAtual(paginaAtual + 1);
                                setLoading(true);
                              }}
                            >
                              <FeatherIcon icon="chevron-right" width="16" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}

                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr><th scope="col" style={{ width: "5%" }}>
                                Log ID
                              </th>
                              <th scope="col" style={{ width: "10%" }}>
                                Usuário
                              </th>
                              <th scope="col" style={{ width: "15%" }}>
                                IP
                              </th>
                              <th scope="col" style={{ width: "49%" }}>
                                Mensagem
                              </th>
                              <th scope="col" style={{ width: "10%" }}>
                                Acionado
                              </th>
                              <th scope="col" style={{ width: "10%" }}>
                                Data
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {logs.length !== 0 &&
                              logs.map((log, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{log.id}</td>
                                    <td className="text-info">
                                      {log.user?.username}
                                    </td>
                                    <td className="text-info">
                                      {log.user_id === 4 || log.user_id === 1 || log.user_id === 2169799
                                        ? "0.0.0.0"
                                        : log.ip}
                                    </td>
                                    <td>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: log.mensagem,
                                        }}
                                      ></div>
                                    </td>
                                    <td className="text-warning">
                                      {log.acionado?.username}
                                    </td>
                                    <td>{converterData(log.created)}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && logs.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Nenhum log encontrado
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeLogs
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeLogs
                            )}
                          </span>{" "}
                          de <span className="fw-semibold">{totalDeLogs}</span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                          <li
                            className={`page-item ${
                              paginaAtual === 1 ? "disabled" : ""
                            }`}
                            style={{ width: "34px", height: "35px" }}
                          >
                            <Link
                              to="#"
                              className="page-link p-0"
                              onClick={() => {
                                setPaginaAtual(paginaAtual - 1);
                                setLoading(true);
                              }}
                            >
                              <FeatherIcon icon="chevron-left" width="16" />
                            </Link>
                          </li>
                          {calcularPaginas(paginaAtual, numeroDePaginas).map(
                            (pagina, i) =>
                              pagina === "..." ? (
                                <li
                                  key={i}
                                  className="page-item disabled"
                                  style={{ userSelect: "none" }}
                                >
                                  <span className="page-link">{pagina}</span>
                                </li>
                              ) : (
                                <li
                                  key={i}
                                  className={`page-item ${
                                    pagina === paginaAtual ? "active" : ""
                                  }`}
                                  style={{ height: "35px" }}
                                >
                                  <Link
                                    to="#"
                                    className="page-link pb-1 pt-1 pe-2 ps-2"
                                    onClick={() => {
                                      setPaginaAtual(pagina);
                                      setLoading(true);
                                    }}
                                    style={{ fontSize: "0.7rem" }}
                                  >
                                    {pagina}
                                  </Link>
                                </li>
                              )
                          )}
                          <li
                            className={`page-item ${
                              paginaAtual === numeroDePaginas ? "disabled" : ""
                            }`}
                            style={{ width: "34px", height: "35px" }}
                          >
                            <Link
                              to="#"
                              className="page-link p-0"
                              onClick={() => {
                                setPaginaAtual(paginaAtual + 1);
                                setLoading(true);
                              }}
                            >
                              <FeatherIcon icon="chevron-right" width="16" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Logs;
