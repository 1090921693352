import React, { useState, useCallback, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalAdicionarMes = ({
  modalAdicionarMes,
  setModalAdicionarMes,
  setRefresh,
  cliente,
}) => {
  const [loading, setLoading] = useState(false);
  const [mesAdicionar, setMesAdicionar] = useState("1");
  const [preco, setPreco] = useState(0);

  const calcularPreco = useCallback(
    async (cliente, mesAdicionar) => {
      const conexoes = cliente?.painel_tipo === 0 ? 1 : cliente?.conexoes;
      const precoOperacao =
        parseInt(mesAdicionar) * conexoes * cliente?.painel?.preco_credito;
      setPreco(precoOperacao);
    },
    [cliente, mesAdicionar]
  );

  const renovar = async (clienteId) => {
    setLoading(true);

    let response = await apiClient.put(
      `/clientes/${clienteId}/${mesAdicionar}/renovar`,
      {}
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setMesAdicionar("1");
      setModalAdicionarMes(!modalAdicionarMes);
    } else {
      response = await apiClient.put(
        `/clientes/${clienteId}/${mesAdicionar}/renovar`,
        {}
      );
      if (response.sucesso) {
        toast.success(response.mensagem);
        localStorage.removeItem("lastUpdated");
        setRefresh(true);
        setMesAdicionar("1");
        setModalAdicionarMes(!modalAdicionarMes);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401")) {
          if (response.mensagem !== "") toast.error(response.mensagem);
        } else {
          await checkLastLogin();
        }
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const rodar = async () => {
      await calcularPreco(cliente, mesAdicionar);
    };
    if (cliente) {
      rodar();
    }
  }, [cliente, mesAdicionar, calcularPreco]);

  return (
    <Modal
      isOpen={modalAdicionarMes}
      toggle={() => {
        setModalAdicionarMes(!modalAdicionarMes);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalAdicionarMes(!modalAdicionarMes);
        }}
      >
        Renovar {cliente?.username}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <h3>Tem certeza disso?</h3>
                <p id="mensagem">
                  Será adicionado 1 mês ao usuário consumindo {preco.toFixed(2)}{" "}
                  crédito(s). Tem certeza de que deseja continuar?
                </p>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className="btn btn-link link-secondary fw-medium"
              onClick={() => setModalAdicionarMes(!modalAdicionarMes)}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-secondary"
              onClick={() => renovar(cliente?.id)}
              disabled={loading}
            >
              Renovar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalAdicionarMes;
