import React from "react";
import { Row, Col } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { toast } from "react-toastify";

const DownloadColuna2 = ({
  teste,
  urlOriginal,
  urlEpg,
  urlEncurtada,
  urlEncurtadaEpg,
}) => {
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Mensagem copiada com sucesso.");
  };

  const getMessageWithoutUrls = () => {
    return `Olá! Aqui estão seus dados de acesso:

Login: ${teste?.externo_login}
Senha: ${teste?.externo_senha}

Para baixar os aplicativos, acesse nossa loja: https://loja.hzplay.fun/

Qualquer dúvida estamos à disposição!`;
  };

  const getMessageWithUrls = () => {
    const urlLista = urlEncurtada || urlOriginal;
    const urlEpgFinal = urlEncurtadaEpg || urlEpg;

    return `${getMessageWithoutUrls()}

URL da Lista: ${urlLista}

URL da EPG: ${urlEpgFinal}`;
  };

  return (
    <div className="mt-4">
      <h5>Mensagem para o cliente</h5>
      <Row className="mt-3">
        <Col xs="12">
          <div className="border rounded p-3 position-relative">
            <div
              className="position-absolute top-0 end-0 m-2 cursor-pointer"
              onClick={() => handleCopy(getMessageWithoutUrls())}
              style={{ cursor: "pointer", zIndex: 1 }}
            >
              <FeatherIcon icon="copy" size={18} />
            </div>
            <SyntaxHighlighter
              language="plaintext"
              style={docco}
              customStyle={{
                background: "transparent",
                padding: 0,
                margin: 0,
              }}
              wrapLongLines={true}
            >
              {getMessageWithoutUrls()}
            </SyntaxHighlighter>
          </div>
        </Col>
      </Row>

      {teste?.painel_tipo === 1 && (
        <Row className="mt-3">
          <Col xs="12">
            <div className="border rounded p-3 position-relative">
              <div
                className="position-absolute top-0 end-0 m-2"
                onClick={() => handleCopy(getMessageWithUrls())}
                style={{ cursor: "pointer", zIndex: 1 }}
              >
                <FeatherIcon icon="copy" size={18} />
              </div>
              <SyntaxHighlighter
                language="plaintext"
                style={docco}
                customStyle={{
                  background: "transparent",
                  padding: 0,
                  margin: 0,
                }}
                wrapLongLines={true}
              >
                {getMessageWithUrls()}
              </SyntaxHighlighter>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DownloadColuna2;
