import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();
import classnames from "classnames";

const ModalMigrarPainel = ({
  modalMigrarPainel,
  setModalMigrarPainel,
  setRefresh,
  clienteId,
}) => {
  const [loading, setLoading] = useState(false);
  const [painelTipo, setPainelTipo] = useState(1);
  const [planosIptv, setPlanosIptv] = useState([]);
  const [planosP2p, setPlanosP2p] = useState([]);
  const [planosP2pPlus, setPlanosP2pPlus] = useState([]);
  const [clienteDados, setClienteDados] = useState({});
  const [planosCliente, setPlanosCliente] = useState([]);

  const arrowNavToggle = (tab) => {
    if (painelTipo !== tab) {
      setPainelTipo(tab);
    }
  };

  const migrar = async () => {
    setLoading(true);

    const data = {
      planos: planosCliente,
    };

    const response = await apiClient.put(
      `/clientes/${clienteId}/migrar/${painelTipo}`,
      data
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setModalMigrarPainel(!modalMigrarPainel);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const fetchData = useCallback(async (id) => {
    setLoading(true);

    const response = await apiClient.get(`/clientes/${id}`);
    if (response.sucesso) {
      const dadosRetorno = response.dados;
      setClienteDados(dadosRetorno.cliente);
      setPlanosIptv(dadosRetorno.planos_iptv);
      setPlanosP2p(dadosRetorno.planos_p2p);
      setPlanosP2pPlus(dadosRetorno.planos_p2p_brasil);
      setPainelTipo(dadosRetorno.cliente.painel_tipo === 1 ? "0" : "1");
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (modalMigrarPainel && clienteId != 0) {
      fetchData(clienteId);
    }
  }, [modalMigrarPainel, fetchData]);
  return (
    <Modal
      isOpen={modalMigrarPainel}
      toggle={() => {
        setModalMigrarPainel(!modalMigrarPainel);
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalMigrarPainel(!modalMigrarPainel);
        }}
      >
        Migrar Painel de {clienteDados?.username}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Nav
            pills
            className="nav nav-pills arrow-navtabs nav-success bg-light mb-3 mt-3"
          >
            {clienteDados.painel_tipo !== 1 && (
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: painelTipo === "1",
                  })}
                  onClick={() => {
                    arrowNavToggle("1");
                  }}
                >
                  IPTV
                </NavLink>
              </NavItem>
            )}
            {clienteDados.painel_tipo !== 0 && (
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: painelTipo === "0",
                  })}
                  onClick={() => {
                    arrowNavToggle("0");
                  }}
                >
                  P2P
                </NavLink>
              </NavItem>
            )}
            {clienteDados.painel_tipo !== 2 && (
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: painelTipo === "2",
                  })}
                  onClick={() => {
                    arrowNavToggle("2");
                  }}
                >
                  P2P Plus
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <h2 htmlFor="painelTipo" className="form-label">
                  Mudando painel para{" "}
                  {painelTipo === "1"
                    ? "IPTV"
                    : painelTipo === "2"
                    ? "P2P Plus"
                    : "P2P"}
                </h2>
                <p>Escolha o(s) planos para o cliente no novo painel.</p>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <Label htmlFor="planos" className="form-label">
                Planos
              </Label>
              <div>
                {parseInt(painelTipo) === 1
                  ? planosIptv.map((plano, index) => (
                      <Col
                        md="12"
                        key={index}
                        className="form-check form-check-inline"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`plano-${plano.id}`}
                          value={plano.id}
                          checked={planosCliente.includes(plano.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPlanosCliente([...planosCliente, plano.id]);
                            } else {
                              setPlanosCliente(
                                planosCliente.filter(
                                  (item) => item !== plano.id
                                )
                              );
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`plano-${plano.id}`}
                        >
                          {plano.nome_plano}
                        </label>
                      </Col>
                    ))
                  : parseInt(painelTipo) === 2
                  ? planosP2pPlus.map((plano, index) => (
                      <Col
                        md="12"
                        key={index}
                        className="form-check form-check-inline"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="planoCliente"
                          id={`plano-${plano.id}`}
                          value={plano.id}
                          checked={planosCliente.includes(plano.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPlanosCliente([plano.id]);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`plano-${plano.id}`}
                        >
                          {plano.nome_plano}
                        </label>
                      </Col>
                    ))
                  : planosP2p.map((plano, index) => (
                      <Col
                        md="12"
                        key={index}
                        className="form-check form-check-inline"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="planoCliente"
                          id={`plano-${plano.id}`}
                          value={plano.id}
                          checked={planosCliente.includes(plano.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPlanosCliente([plano.id]);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`plano-${plano.id}`}
                        >
                          {plano.nome_plano}
                        </label>
                      </Col>
                    ))}
              </div>
            </Col>
            {painelTipo !== "1" && clienteDados.painel_tipo === 1 && (
              <small className="text-muted">
                Será cobrado um adicional de 0.2 créditos por cada mês restante
                no painel atual.
              </small>
            )}
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-info fw-medium"
              onClick={() => setModalMigrarPainel(!modalMigrarPainel)}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-info"
              onClick={() => migrar()}
              disabled={loading}
            >
              Migrar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalMigrarPainel;
