import React, { useEffect, useState, useCallback } from "react";

import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledCollapse,
  Alert,
  Spinner,
} from "reactstrap";

import classnames from "classnames";

import FeatherIcon from "feather-icons-react";
import DeleteModal from "../../Components/Common/DeleteModal";
import { useQuill } from "react-quilljs";
//SimpleBar
import SimpleBar from "simplebar-react";

import ModalCompose from "./ModalCompose";
import ModalResponder from "./ModalResponder";
import {
  checkLastLogin,
  limparMensagem,
} from "../../Components/FunctionsRepository";
import { toast } from "react-toastify";

import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const EmailToolbar = () => {
  const [loading, setLoading] = useState(true);
  const [loadingRevendas, setLoadingRevendas] = useState(true);
  const [tickets, setTickets] = useState({
    horizon: [],
    revendedores: [],
    parent: [],
  });

  const [emailinfo, setEmailinfo] = useState({});
  const [activeTabs, setActive] = useState("all");
  const [isLabelTab, setIsLabelTab] = useState("");
  const [isTypeTab, setIsTypeTab] = useState("horizon");

  const [refresh, setRefresh] = useState(true);
  const [revendedores, setRevendedores] = useState([]);
  const [userData, setUserData] = useState({});

  const converterData = (dataISO) => {
    if (dataISO) {
      const ano = dataISO.substring(0, 4);
      const mes = dataISO.substring(5, 7);
      const dia = dataISO.substring(8, 10);
      const hora = dataISO.substring(11, 13);
      const minuto = dataISO.substring(14, 16);

      return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
    }
    return "";
  };

  const marcarLido = async (id) => {
    setLoading(true);

    const response = await apiClient.post(`/tickets/marcar-lido/${id}`, {});
    if (response?.sucesso) {
      console.log(
        response?.mensagem || "Ticket de suporte atualizado com sucesso."
      );
      setRefresh(true);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const encerra = async (id) => {
    setLoading(true);

    const response = await apiClient.post(`/tickets/encerra/${id}`, {});
    if (response?.sucesso) {
      toast.success(
        response?.mensagem || "Ticket de suporte encerrado com sucesso."
      );
      setRefresh(true);
      sidebarClose("email-detail-show");
      setEmailinfo({});
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const fetchRevendedores = useCallback(async () => {
    setLoadingRevendas(true);

    const response = await apiClient.get("/revendedores");

    if (response?.sucesso) {
      setRevendedores(response?.dados);
    } else {
      console.error(response?.mensagem);
      if (response.mensagem !== "") toast.error(response?.mensagem);
    }

    setLoadingRevendas(false);
  }, []);

  const toggleTab = (ncategory, ntype, nlabel) => {
    var element = document.getElementById("mail-filter-navlist");
    if (ncategory === "all" || ncategory === "inbox") {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
    if (activeTabs !== ncategory) {
      setActive(ncategory);
    }
    if (isLabelTab !== nlabel) {
      setIsLabelTab(nlabel);
    }
    if (isTypeTab !== ntype) {
      setIsTypeTab(ntype);
    }
  };

  // SideBar Open
  function sidebarOpen(value, ele) {
    const element = document.getElementsByTagName("body")[0];
    element?.classList?.add(value);
  }

  // SideBar Close
  function sidebarClose(value) {
    const element = document.getElementsByTagName("body")[0];
    element?.classList?.remove(value);
  }

  const [modal, setModal] = useState(false);
  const [modalAtualizar, setModalAtualizar] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleAtualizar = () => {
    setModalAtualizar(!modalAtualizar);
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);

    const response = await apiClient.get("/tickets/0");
    if (response?.sucesso) {
      const todosTickets = response?.dados;
      let retorno = {
        horizon: [],
        revendedores: [],
        parent: [],
      };
      todosTickets.forEach((ticket) => {
        if (ticket.id_destinatario === 1 || ticket.user_id === 1) {
          retorno.horizon.push(ticket);
        } else if (
          ticket.id_destinatario === userData.parent_id ||
          ticket.user_id === userData.parent_id
        ) {
          retorno.parent.push(ticket);
        } else {
          retorno.revendedores.push(ticket);
        }
      });
      setTickets(retorno);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    const executarFetchData = async () => {
      await fetchData();
    };
    const executarFetchRevendas = async () => {
      await fetchRevendedores();
    };

    if (refresh) {
      executarFetchData();
      setRefresh(false);
    }
    if (modal && revendedores?.length === 0) executarFetchRevendas();
    const usuario = JSON.parse(localStorage.getItem("authUser"));
    setUserData(usuario);
  }, [fetchRevendedores, modal, fetchData, refresh]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => {
          removeEmail();
          setDeleteModal(false);
        }}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="email-content bg-transparent border mt-5">
        <div className="p-4 pb-0">
          <div className="border-bottom border-bottom-dashed">
            <Row className="mt-n2 mb-3 mb-sm-0">
              <Col className="col-sm-auto order-1 d-block d-lg-none">
                <button
                  type="button"
                  className="btn btn-soft-success btn-icon btn-sm fs-16 email-menu-btn"
                >
                  <i className="ri-menu-2-fill align-bottom"></i>
                </button>
              </Col>
              <div className="col-sm order-3 order-sm-2">
                <div className="hstack gap-sm-1 align-items-center flex-wrap email-topbar-link">
                  <div className="form-check fs-14 m-0">{/* Check all */}</div>

                  <div className="align-self-center mx-2"></div>

                  <Alert
                    color="warning"
                    style={{ display: "none" }}
                    className="unreadConversations-alert px-4 fade"
                    id="unreadConversations"
                  >
                    Nenhuma mensagem não lida
                  </Alert>
                </div>
              </div>
              <div className="col-auto order-2 order-sm-3">
                <div className="d-flex gap-sm-1 email-topbar-link">
                  <button
                    type="button"
                    className="btn btn-danger w-100"
                    onClick={() => {
                      toggle();
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      "Carregando..."
                    ) : (
                      <>
                        <FeatherIcon
                          icon="plus-circle"
                          className="icon-xs me-1 icon-dual-light"
                        />
                        Abrir Suporte
                      </>
                    )}
                  </button>
                </div>
              </div>
            </Row>

            <Row className="row align-items-end mt-3">
              <Col>
                <div id="mail-filter-navlist">
                  <Nav
                    className="nav nav-tabs nav-tabs-custom nav-primary gap-1 text-center border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: isTypeTab === "horizon" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("all", "horizon", "all");
                        }}
                        href="#"
                      >
                        <i className="ri-inbox-fill align-bottom d-inline-block"></i>
                        <span className="ms-1 d-none d-sm-inline-block">
                          Horizon
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: isTypeTab === "revendedores" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("all", "revendedores", "all");
                        }}
                        href="#"
                      >
                        <i className="ri-group-fill align-bottom d-inline-block"></i>
                        <span className="ms-1 d-none d-sm-inline-block">
                          Revendedores
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: isTypeTab === "parent" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("all", "parent", "all");
                        }}
                        href="#"
                      >
                        <i className="ri-price-tag-3-fill align-bottom d-inline-block"></i>
                        <span className="ms-1 d-none d-sm-inline-block">
                          Meu Fornecedor de Créditos
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
            </Row>
          </div>

          <div className="message-list-content mx-n4 px-4 message-list-scroll">
            {loading ? (
              <div id="elmLoader">
                <div
                  className="spinner-border text-primary avatar-sm text-center"
                  role="status"
                >
                  <Spinner />
                </div>
              </div>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>De</th>
                    <th>Para</th>
                    <th>Status</th>
                    <th>Assunto</th>
                    <th>Texto</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody id="mail-list">
                  {tickets[isTypeTab].map((item, key) => (
                    <tr
                      style={
                        item?.caixa_remetente === userData.data.id &&
                        item?.caixa_remetente === 0
                          ? { fontWeight: "bold" }
                          : item?.caixa_destinatario === userData.data.id &&
                            item?.caixa_destinatario === 0
                          ? { fontWeight: "bold" }
                          : {}
                      }
                      key={key}
                      onClick={(e) => {
                        sidebarOpen("email-detail-show", e.target);
                        setEmailinfo(item);
                        const executar = async () => {
                          if (
                            !(
                              item?.status === 1 &&
                              item?.mensagens?.length === 1
                            )
                          )
                            await marcarLido(item?.id);
                        };
                        executar();
                      }}
                    >
                      <td className="text-muted"># {item?.id}</td>
                      <td>
                        {item?.remetente?.nome} <br />
                        <small className="text-muted">
                          {item?.remetente?.username}
                        </small>
                      </td>
                      <td>
                        {item?.destinatario?.nome} <br />
                        <small className="text-muted">
                          {item?.destinatario?.username}
                        </small>
                      </td>
                      <td>
                        {item?.status === 0 ? (
                          <span className="badge bg-success">Novo</span>
                        ) : item?.status === 1 &&
                          item?.mensagens?.length === 1 ? (
                          <span className="badge bg-secondary">Lido</span>
                        ) : item?.status === 1 &&
                          item?.mensagens?.length === 2 ? (
                          <span className="badge bg-primary">Respondido</span>
                        ) : item?.status === 1 ? (
                          <span className="badge bg-info">Em Andamento</span>
                        ) : (
                          <span className="badge bg-danger">Encerrado</span>
                        )}
                      </td>
                      <td>{item?.assunto}</td>
                      <td className="teaser">
                        {limparMensagem(
                          item?.mensagens[item?.mensagens?.length - 1]?.mensagem
                        )}
                      </td>
                      <td className="date" style={{ whiteSpace: "nowrap" }}>
                        {converterData(item?.modified)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <div className="email-detail-content border">
        <div className="p-4 d-flex flex-column h-100">
          <div className="pb-4 border-bottom border-bottom-dashed">
            <Row>
              <Col className="col">
                <div className="">
                  <button
                    type="button"
                    className="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"
                    id="close-btn-email"
                    onClick={() => sidebarClose("email-detail-show")}
                  >
                    <i className="ri-close-fill align-bottom"></i>
                  </button>
                </div>
              </Col>
              <Col className="col-auto">
                <div className="hstack gap-sm-1 align-items-center flex-wrap email-topbar-link">
                  <button
                    className="btn btn-ghost-secondary btn-icon btn-sm fs-16 remove-mail"
                    data-remove-id=""
                    data-bs-target="#removeItemModal"
                    title="Encerrar Suporte"
                    onClick={() => {
                      encerra(emailinfo.id);
                    }}
                  >
                    <i className="ri-delete-bin-5-fill align-bottom"></i>
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          <SimpleBar className="mx-n4 px-4 email-detail-content-scroll h-100">
            <div className="mt-4 mb-3">
              <h5 className="fw-bold email-subject-title">
                {emailinfo.assunto}
              </h5>
            </div>
            <div className="justify-content-end d-flex">
              <button
                type="button"
                className="btn btn-sm btn-success"
                onClick={() => {
                  toggleAtualizar();
                }}
                disabled={loading}
              >
                Responder
              </button>
              <ModalResponder
                modal={modalAtualizar}
                toggle={toggleAtualizar}
                sidebarClose={sidebarClose}
                setModal={setModalAtualizar}
                revendedores={revendedores}
                setRefresh={setRefresh}
                loading={loading}
                ticket={emailinfo}
              />
            </div>
            <div className="accordion accordion-flush mt-3">
              {emailinfo?.mensagens?.map((mensagem, key) => {
                return (
                  <div className="accordion-item border-dashed left" key={key}>
                    <div className="accordion-header">
                      <a
                        role="button"
                        href="/#"
                        className={`btn w-100 text-start px-0 bg-transparent shadow-none ${
                          key === emailinfo.mensagens?.length - 1
                            ? ""
                            : "collapsed"
                        }`}
                        id="email-collapseOne"
                      >
                        <div className="d-flex align-items-center text-muted">
                          <div className="flex-shrink-0 avatar-xs me-3">
                            {mensagem.user_id === userData.data.id ? (
                              <FeatherIcon
                                className="img-fluid rounded-circle text-success"
                                icon="user"
                                width="100%"
                              />
                            ) : (
                              <FeatherIcon
                                className="img-fluid rounded-circle text-primary"
                                icon="users"
                                width="100%"
                              />
                            )}
                          </div>
                          <div className="flex-grow-1 overflow-hidden">
                            <h5 className="fs-14 text-truncate mb-0 email-user-name">
                              {mensagem.user_id === emailinfo.remetente.id
                                ? emailinfo.remetente.nome
                                : emailinfo.destinatario.nome}
                            </h5>
                            <div className="text-truncate fs-12">
                              para:{" "}
                              {mensagem.user_id === emailinfo.remetente.id
                                ? emailinfo.destinatario.nome
                                : emailinfo.remetente.nome}
                            </div>
                          </div>
                          <div className="flex-shrink-0 align-self-start">
                            <div className="text-muted fs-12">
                              {mensagem.created &&
                                converterData(mensagem.created)}
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>

                    <UncontrolledCollapse
                      toggler="#email-collapseOne"
                      defaultOpen={key === emailinfo.mensagens?.length - 1}
                    >
                      <div
                        className="accordion-body text-body px-0"
                        dangerouslySetInnerHTML={{ __html: mensagem?.mensagem }}
                      ></div>
                    </UncontrolledCollapse>
                  </div>
                );
              })}
            </div>
          </SimpleBar>
        </div>
      </div>

      <ModalCompose
        modal={modal}
        toggle={toggle}
        setModal={setModal}
        revendedores={revendedores}
        setRefresh={setRefresh}
        loading={loadingRevendas}
      />
    </React.Fragment>
  );
};

export default EmailToolbar;
