import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalBloquearClientes = ({
  modalBloquearClientes,
  setModalBloquearClientes,
  setRefresh,
  revendedor,
}) => {
  const [loading, setLoading] = useState(false);
  const [motivo, setMotivo] = useState("");
  const [userDados, setUserDados] = useState({});
  const [acao, setAcao] = useState("bloquear"); // 'bloquear' ou 'desbloquear'

  const getUserDados = async () => {
    const dados = JSON.parse(localStorage.getItem("authUser"));
    setUserDados(dados.data);
  };

  const bloquearClientes = async () => {
    setLoading(true);

    const endpoint = `/revendedores/clientes/${acao === 'bloquear' ? 'bloqueio' : 'desbloqueio'}`;
    const data = { 
      motivo,
      revenda_id: revendedor.id,
      arvore: false
    };

    const response = await apiClient.post(endpoint, data);
    if (response.sucesso) {
      toast.success(response.mensagem || `Clientes ${acao === 'bloquear' ? 'bloqueados' : 'desbloqueados'} com sucesso.`);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setMotivo("");
      setModalBloquearClientes(!modalBloquearClientes);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUserDados();
    };
    fetchData();
  }, []);

  return (
    <Modal
      isOpen={modalBloquearClientes}
      toggle={() => {
        setModalBloquearClientes(!modalBloquearClientes);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalBloquearClientes(!modalBloquearClientes);
        }}
      >
        {acao === 'bloquear' ? "Bloquear" : "Desbloquear"} Clientes de {revendedor?.nome}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <FormGroup tag="fieldset">
            <legend>Selecione a ação</legend>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="acao"
                  value="bloquear"
                  checked={acao === 'bloquear'}
                  onChange={(e) => setAcao(e.target.value)}
                />{' '}
                Bloquear clientes
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="acao"
                  value="desbloquear"
                  checked={acao === 'desbloquear'}
                  onChange={(e) => setAcao(e.target.value)}
                />{' '}
                Desbloquear clientes
              </Label>
            </FormGroup>
          </FormGroup>

          {userDados?.id !== 1 && (
            <>
              <Row className="mt-3">
                <small className="text-muted">
                  Este formulário envia um pedido para {acao === 'bloquear' ? 'bloquear' : 'desbloquear'} os clientes do revendedor.
                  O pedido será analisado e, se aprovado, os clientes serão {acao === 'bloquear' ? 'bloqueados' : 'desbloqueados'}.
                </small>
              </Row>
              <Row className="mt-3">
                <div>
                  <Label htmlFor="motivo" className="form-label">
                    Motivo
                  </Label>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="motivo"
                    value={motivo}
                    onChange={(e) => setMotivo(e.target.value)}
                    maxLength={255}
                  />
                </div>
              </Row>
            </>
          )}

          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className={`btn btn-link link-${acao === 'bloquear' ? "info" : "danger"} fw-medium`}
              onClick={() => setModalBloquearClientes(!modalBloquearClientes)}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className={`btn btn-${acao === 'bloquear' ? "info" : "danger"}`}
              onClick={bloquearClientes}
              disabled={loading}
            >
              {acao === 'bloquear' ? "Bloquear" : "Desbloquear"} Clientes
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalBloquearClientes;
