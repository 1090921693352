import React, { useState, useCallback, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  Card,
  CardBody,
  Collapse,
  CardHeader,
} from "reactstrap";
import classnames from "classnames";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const Utilidades = () => {
  const [borderCol1, setborderCol1] = useState(false);
  const [borderCol2, setborderCol2] = useState(false);
  const [borderCol3, setborderCol3] = useState(false);
  const [borderCol4, setborderCol4] = useState(false);
  const [borderCol5, setborderCol5] = useState(true);
  const [borderCol6, setborderCol6] = useState(false);
  const [dominios, setDominios] = useState([]);

  const t_borderCol1 = () => {
    setborderCol1(!borderCol1);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
  };

  const t_borderCol2 = () => {
    setborderCol2(!borderCol2);
    setborderCol1(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
  };

  const t_borderCol3 = () => {
    setborderCol3(!borderCol3);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
  };

  const t_borderCol4 = () => {
    setborderCol4(!borderCol4);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol5(false);
    setborderCol6(false);
  };

  const t_borderCol5 = () => {
    setborderCol5(!borderCol5);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol6(false);
  };

  const t_borderCol6 = () => {
    setborderCol6(!borderCol6);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
  };

  const fetchDominios = useCallback(async () => {
    const response = await apiClient.get(`/url-lista`);
    if (response.sucesso) {
      setDominios(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  useEffect(() => {
    const executar = async () => {
      await fetchDominios();
    };

    executar();
  }, [fetchDominios]);

  return (
    <Card>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Utilidades</h4>
      </CardHeader>
      <CardBody>
        <div className="live-preview">
          <Accordion
            className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-success"
            id="accordionBordered"
            toggle={() => {}}
            open=""
          >
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample1">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol5,
                  })}
                  type="button"
                  onClick={t_borderCol5}
                  style={{ cursor: "pointer" }}
                >
                  Central do Cliente
                </button>
              </h2>
              <Collapse
                isOpen={borderCol5}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse1"
              >
                <div className="accordion-body">
                  <p>
                    Acesse o link com o login e senha do cliente:{" "}
                    <a
                      className="text-info"
                      href="https://clientes.hzx.digital/login"
                    >
                      https://clientes.hzx.digital/login
                    </a>
                    <br />
                    <br />
                    Nela conta com todas as informações como:
                  </p>
                  <ul>
                    <li>- Vencimento </li>
                    <li>- Dias restantes </li>
                    <li>- Auto Renovar (caso esteja configurado no painel) </li>
                    <li>- Dados do revendedor (caso possua) </li>
                    <li>- Loja de Apps</li>
                    <li>- Webplayer (caso seja IPTV) </li>
                  </ul>
                  <p>
                    Em breve vamos implementar mais ferramentas nessa central
                    para melhor comodidade ao seu cliente.
                  </p>
                </div>
              </Collapse>
            </AccordionItem>
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample1">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol6,
                  })}
                  type="button"
                  onClick={t_borderCol6}
                  style={{ cursor: "pointer" }}
                >
                  Mag Portal
                </button>
              </h2>
              <Collapse
                isOpen={borderCol6}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse1"
              >
                <div className="accordion-body">
                  <b className="text-warning">
                    Opções de MAG Portal: <br />
                  </b>
                  <ul>
                    {dominios.map((dominio) => (
                      <li key={dominio.id}>
                        <small>
                          <b>{dominio.dominio}c</b>
                        </small>
                      </li>
                    ))}
                  </ul>
                </div>
              </Collapse>
            </AccordionItem>
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample1">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol1,
                  })}
                  type="button"
                  onClick={t_borderCol1}
                  style={{ cursor: "pointer" }}
                >
                  Web Player e DNS
                </button>
              </h2>
              <Collapse
                isOpen={borderCol1}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse1"
              >
                <div className="accordion-body">
                  <ul>
                    <li>
                      <b className="text-warning">WEB PLAYER:</b>
                      <br />- Opção 1:{" "}
                      <a className="text-info" href="http://hzna.live">
                        http://hzna.live
                      </a>{" "}
                      <br />- Opção 2:{" "}
                      <a className="text-info" href="http://hzplay.life/">
                        http://hzplay.life/
                      </a>{" "}
                      <br />- Opção 3:{" "}
                      <a className="text-info" href="http://webdoseumelhor.top">
                        http://webdoseumelhor.top
                      </a>
                      <br />
                      (acesso com login e senha do IPTV)
                    </li>
                    <li className="mt-3">
                      <b className="text-warning">
                        DNS PARA USAR COM APP SMARTERS OU XCIPTV OU ALGUM OUTRO
                        QUE SOLICITE DNS:
                      </b>
                      <br />
                      {dominios.map((dominio) => (
                        <div key={dominio.id}>
                          <b>- {dominio.dominio}</b>
                          <br />
                        </div>
                      ))}
                    </li>
                  </ul>
                </div>
              </Collapse>
            </AccordionItem>
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample2">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol2,
                  })}
                  type="button"
                  onClick={t_borderCol2}
                  style={{ cursor: "pointer" }}
                >
                  DNS Smart Up / IPTV Portal
                </button>
              </h2>
              <Collapse
                isOpen={borderCol2}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse2"
              >
                <div className="accordion-body">
                  <ul>
                    <li>
                      LG e Samsung - Usar login gerado na opção Xtream do
                      painel.
                    </li>
                    <br />
                    <li>
                      <b>DNS para Substituição:</b>
                      <br />
                      Opção 1: 66.206.24.50
                      <br />
                      Opção 2: 167.114.201.127
                      <br />
                      Opção 3: 216.238.118.246
                    </li>
                    <br />
                    <li>Usar app Smart UP, IPTV Portal.</li>
                    <br />
                    <li>
                      <b>Como trocar o DNS na Samsung série antiga:</b>
                      <br />
                      <a
                        className="text-info"
                        href="https://youtu.be/0gupov0NkZY"
                      >
                        https://youtu.be/0gupov0NkZY
                      </a>
                    </li>
                    <br />
                    <li>
                      <b>Como trocar o DNS na Samsung Tizen:</b>
                      <br />
                      <a
                        href="https://www.youtube.com/watch?v=MfdxMbqdfPc"
                        className="text-info"
                      >
                        https://www.youtube.com/watch?v=MfdxMbqdfPc
                      </a>
                    </li>
                    <br />
                    <li>
                      <b>Como Alterar o DNS na LG (antiga):</b>
                      <br />
                      <a
                        href="https://www.youtube.com/watch?v=aacqd-G35vQ"
                        className="text-info"
                      >
                        https://www.youtube.com/watch?v=aacqd-G35vQ
                      </a>
                    </li>
                    <br />
                    <li>
                      <b>Alterar o DNS na LG WebOs:</b>
                      <br />
                      <a
                        href="https://www.youtube.com/watch?v=fuNiSt5SAEw"
                        className="text-info"
                      >
                        https://www.youtube.com/watch?v=fuNiSt5SAEw
                      </a>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </AccordionItem>
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample3">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol3,
                  })}
                  type="button"
                  onClick={t_borderCol3}
                  style={{ cursor: "pointer" }}
                >
                  Aplicativos Playstore
                </button>
              </h2>
              <Collapse
                isOpen={borderCol3}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse3"
              >
                <div className="accordion-body">
                  <ul>
                    <li>
                      <b>App M7 Downloads:</b>
                      <br />
                      <a
                        href="https://play.google.com/store/apps/details?id=com.m7.downloads"
                        className="text-info"
                      >
                        https://play.google.com/store/apps/details?id=com.m7.downloads
                      </a>
                    </li>
                    <br />
                    <li>
                      <b>XS Control:</b>
                      <br />
                      <a
                        href="https://play.google.com/store/apps/details?id=com.navegador2.xtreme&hl=pt_BR&gl=US"
                        className="text-info"
                      >
                        https://play.google.com/store/apps/details?id=com.navegador2.xtreme&hl=pt_BR&gl=US
                      </a>
                    </li>
                    <br />
                    <li>
                      <b>App Downloader:</b>
                      <br />
                      <a
                        href="https://play.google.com/store/apps/details?id=com.esaba.downloader"
                        className="text-info"
                      >
                        https://play.google.com/store/apps/details?id=com.esaba.downloader
                      </a>
                    </li>
                    <br />
                    <li>
                      <b>Loja Oficial de Aplicativos:</b>
                      <br />
                      <a href="https://loja.hzplay.fun/" className="text-info">
                        https://loja.hzplay.fun/
                      </a>
                    </li>
                    <br />
                    <li>
                      <b>APP P2P Plus:</b>
                      <br />
                      P2P Plus 1.1.8
                      <br />
                      <a href="https://bit.ly/hzplus118" className="text-info">
                        https://bit.ly/hzplus118
                      </a>
                      <br />
                      Código Downloader: 1361206
                      <br />
                      Código M7 ou XS Control: 1362
                    </li>
                    <br />
                    <li>
                      <b>APP P2P:</b>
                      <br />
                      P2P 5.6.5
                      <br />
                      <a href="https://bit.ly/hz565" className="text-info">
                        https://bit.ly/hz565
                      </a>
                      <br />
                      Código Downloader: 259537
                      <br />
                      Código M7 ou XS Control: 1565
                    </li>
                    <br />
                    <li>
                      <b>P2P 5.6.5 com DNS:</b>
                      <br />
                      <a href="https://bit.ly/hz565d" className="text-info">
                        https://bit.ly/hz565d
                      </a>
                      <br />
                      Código Downloader: 562916
                      <br />
                      Código M7 ou XS Control: 5651
                    </li>
                    <br />
                    <li>
                      <b>P2P 5.6.6 Lite:</b>
                      <br />
                      <a href="https://bit.ly/hzp566lite" className="text-info">
                        https://bit.ly/hzp566lite
                      </a>
                      <br />
                      Código Downloader: 919834
                      <br />
                      Código M7 ou XS Control: 0566
                    </li>
                    <br />
                    <li>
                      <b>P2P 5.8.6:</b>
                      <br />
                      <a href="https://bit.ly/hz586" className="text-info">
                        https://bit.ly/hz586
                      </a>
                      <br />
                      Código Downloader: 339055
                      <br />
                      Código M7 ou XS Control: 5860
                    </li>
                    <br />
                    <li>
                      <b>APPs IPTV:</b>
                      <br />
                      <b>HZ Smarters:</b>
                      <br />
                      <a href="https://bit.ly/hzsmarters" className="text-info">
                        https://bit.ly/hzsmarters
                      </a>
                      <br />
                      Código Downloader: 769792
                      <br />
                      Código M7 ou XS Control: 9347
                    </li>
                    <br />
                    <li>
                      <b>HZ XCIPTV:</b>
                      <br />
                      <a href="https://bit.ly/hzxciptv" className="text-info">
                        https://bit.ly/hzxciptv
                      </a>
                      <br />
                      Código Downloader: 737805
                      <br />
                      Código M7 ou XS Control: 7627
                    </li>
                    <br />
                    <li>
                      <b>HZ STREAM PLAYER:</b>
                      <br />
                      <a href="https://bit.ly/hzxtream" className="text-info">
                        https://bit.ly/hzxtream
                      </a>
                      <br />
                      Código Downloader: 467852
                      <br />
                      Código M7 ou XS Control: 7873
                    </li>
                  </ul>
                </div>
              </Collapse>
            </AccordionItem>
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample4">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol4,
                  })}
                  type="button"
                  onClick={t_borderCol4}
                  style={{ cursor: "pointer" }}
                >
                  Vídeos Explicativos do Horizon
                </button>
              </h2>
              <Collapse
                isOpen={borderCol4}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse4"
              >
                <div className="accordion-body">
                  <ul>
                    <li>
                      <b>Explicando como funciona o painel:</b>
                      <br />
                      <a
                        href="https://youtu.be/F0Vv8pe-84o"
                        className="text-info"
                      >
                        https://youtu.be/F0Vv8pe-84o
                      </a>
                    </li>
                    <br />
                    <li>
                      <b>Cadastrando Revenda:</b>
                      <br />
                      <a
                        href="https://youtu.be/qo0zqPj19rE"
                        className="text-info"
                      >
                        https://youtu.be/qo0zqPj19rE
                      </a>
                    </li>
                    <br />
                    <li>
                      <b>Configurando Mercado Pago:</b>
                      <br />
                      <a
                        href="https://youtu.be/x9k7mAufUaM"
                        className="text-info"
                      >
                        https://youtu.be/x9k7mAufUaM
                      </a>
                    </li>
                    <br />
                    <li>
                      <b>Comprando Crédito de Revenda direto pelo painel:</b>
                      <br />
                      <a
                        href="https://youtu.be/_35MB18r3yg"
                        className="text-info"
                      >
                        https://youtu.be/_35MB18r3yg
                      </a>
                    </li>
                    <br />
                    <li>
                      <b>Renovação automática do seu cliente Horizon:</b>
                      <br />
                      <a
                        href="https://youtu.be/DKgQYeSxRGE"
                        className="text-info"
                      >
                        https://youtu.be/DKgQYeSxRGE
                      </a>
                    </li>
                    <br />
                    <li>
                      <b>Instalando Sistema P2P com aplicativo Downloader:</b>
                      <br />
                      <a
                        href="https://youtu.be/XFAKAVXxXI4"
                        className="text-info"
                      >
                        https://youtu.be/XFAKAVXxXI4
                      </a>
                    </li>
                    <br />
                    <li>
                      <b>Instalando Sistema P2P com aplicativo Downloader 2:</b>
                      <br />
                      <a
                        href="https://www.youtube.com/watch?v=9BlQ76RiA4g"
                        className="text-info"
                      >
                        https://www.youtube.com/watch?v=9BlQ76RiA4g
                      </a>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </AccordionItem>
          </Accordion>
        </div>
      </CardBody>
    </Card>
  );
};

export default Utilidades;
