import React, { useState, useEffect, useCallback } from "react";

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Label,
  Input,
  Spinner,
} from "reactstrap";

import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
import { checkLastLogin } from "../../Components/FunctionsRepository";

const apiClient = new APIClient();

const UnlimitedUser = ({ userDados, refreshUser }) => {
  const [externoLogin, setExternoLogin] = useState(false);
  const [externoSenha, setExternoSenha] = useState("");
  const [idExterno, setIdExterno] = useState(0);
  const [painelTipo, setPainelTipo] = useState(0);
  const [planosCliente, setPlanosCliente] = useState([]);
  const [planos, setPlanos] = useState([]);
  const [loading, setLoading] = useState(false);

  const [urlOriginal, setUrlOriginal] = useState("");
  const [urlEpg, setUrlEpg] = useState("");
  const [urlEncurtada, setUrlEncurtada] = useState("");
  const [urlEncurtadaEpg, setUrlEncurtadaEpg] = useState("");
  const [tipo, setTipo] = useState("m3u8");

  const [dominios, setDominios] = useState([]);
  const [dominio, setDominio] = useState("");

  const alterarTipo = (tipo, cliente, dominio) => {
    if (tipo === "m3u8") {
      setUrlOriginal(
        `${dominio}get.php?username=${cliente?.externo_login}&password=${cliente?.externo_senha}&type=m3u_plus&output=m3u8`
      );
      setUrlEpg(
        `${dominio}xmltv.php?username=${cliente?.externo_login}&password=${cliente?.externo_senha}&type=m3u_plus&output=m3u8`
      );
    } else if (tipo === "ts") {
      setUrlOriginal(
        `${dominio}get.php?username=${cliente?.externo_login}&password=${cliente?.externo_senha}&type=m3u_plus&output=ts`
      );
      setUrlEpg(
        `${dominio}xmltv.php?username=${cliente?.externo_login}&password=${cliente?.externo_senha}&type=m3u_plus&output=ts`
      );
    } else if (tipo === "ssiptv") {
      setUrlOriginal(
        `${dominio}ssiptv.php?username=${cliente?.externo_login}&password=${cliente?.externo_senha}`
      );
      setUrlEpg(
        `${dominio}xmltv.php?username=${cliente?.externo_login}&password=${cliente?.externo_senha}&type=m3u_plus&output=m3u8`
      );
    }
    setTipo(tipo);
  };

  const fetchDominios = useCallback(async (cliente) => {
    setLoading(true);
    if (dominios.length > 0 && dominio !== "") {
      alterarTipo("m3u8", cliente, dom.dominio);
      setLoading(false);
      return;
    }

    const response = await apiClient.get(`/url-lista`);
    if (response.sucesso) {
      setDominios(response.dados);
      if (response.dados.length > 0) {
        const dom = response.dados[0];
        setDominio(dom.dominio);
        alterarTipo("m3u8", cliente, dom.dominio);
      }
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  }, []);

  function validarURL(string) {
    const padrao = new RegExp(
      "^(https?:\\/\\/)?" + // protocolo
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // nome de domínio
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OU endereço ip (v4)
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // porta e caminho
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!padrao.test(string);
  }

  const encurtar = async (tipo) => {
    setLoading(true);
    if (!validarURL(urlOriginal)) {
      toast.error("URL inválida para encurtar.");
      setLoading(false);
      return;
    }

    const data = {
      url_original: tipo === "url" ? urlOriginal : urlEpg,
    };

    const response = await apiClient.post("/clientes/encurtar-url", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      if (tipo === "url") setUrlEncurtada(response.dados);
      else setUrlEncurtadaEpg(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const getData = useCallback(async (dados) => {
    setLoading(true);

    const tipo = dados.painel_tipo || 0;
    const id_externo = dados.id_externo || 0;
    setExternoLogin(dados.username);
    setExternoSenha(id_externo === 0 ? "" : dados.externo_senha);
    setPainelTipo(tipo);
    setIdExterno(id_externo);

    const response = await apiClient.get("/planos");
    if (response.sucesso) {
      setPlanos(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  }, []);

  const criar = async (dados) => {
    setLoading(true);

    const data = {
      password: externoSenha,
      painel_tipo: painelTipo,
      planos: planosCliente,
    };

    const response = await apiClient.post(
      `/clientes/${dados.id}/ilimitado/criar`,
      data
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      await refreshUser();
      await getData();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const editar = async (dados) => {
    setLoading(true);

    const data = {
      password: externoSenha,
      painel_tipo: painelTipo,
      planos: planosCliente,
    };

    const response = await apiClient.put(
      `/clientes/${dados.id}/ilimitado/editar`,
      data
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      await refreshUser();
      await getData();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  // Route::put('/{id}/ilimitado/refazer',
  const refazer = async (dados) => {
    setLoading(true);

    const response = await apiClient.put(
      `/clientes/${dados.id}/ilimitado/refazer`
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      await refreshUser();
      await getData();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async (userDados) => {
      await getData(userDados);
      await fetchDominios(userDados);
    };
    if (planos.length === 0) fetchData(userDados);
  }, [userDados]);

  return (
    <>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            <b>Usuário IPTV / P2P Ilimitado</b>
          </h4>
          <small className="text-muted">
            Ao completar o número de 100 clientes ativos, você recebe um usuário
            ilimitado.
          </small>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg="6" className="mt-3">
              <div className="form-group">
                <Label className="form-label">Login IPTV / P2P</Label>
                <Input
                  id="externoLogin"
                  placeholder="Digite o login IPTV"
                  type="text"
                  value={externoLogin}
                  onChange={(e) => setExternoLogin(e.target.value)}
                  disabled
                />
              </div>
            </Col>
            <Col lg="6" className="mt-3">
              <div className="form-group">
                <Label className="form-label">Senha IPTV / P2P</Label>
                <Input
                  id="externoSenha"
                  placeholder="Digite a senha IPTV"
                  type="text"
                  value={externoSenha}
                  onChange={(e) => setExternoSenha(e.target.value)}
                  maxLength={20}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="painelTipo" className="form-label">
                  Painel Tipo <span className="text-danger">*</span>
                </Label>
                <Input
                  type="select"
                  id="painelTipo"
                  className="form-select"
                  value={painelTipo}
                  onChange={(e) => {
                    setPainelTipo(e.target.value);
                    setPlanosCliente([]);
                  }}
                >
                  <option value="0">P2P</option>
                  <option value="1">IPTV</option>
                  <option value="2">P2P Plus</option>
                </Input>
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="planos" className="form-label">
                  Planos <span className="text-danger">*</span>
                </Label>
                {planos.map((plano, index) => {
                  if (plano.painel_tipo === parseInt(painelTipo)) {
                    return parseInt(painelTipo) === 1 ? (
                      <Col
                        md="12"
                        key={index}
                        className="form-check form-check-inline"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`plano-${plano.id}`}
                          value={plano.id}
                          checked={planosCliente.includes(plano.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPlanosCliente([...planosCliente, plano.id]);
                            } else {
                              setPlanosCliente(
                                planosCliente.filter(
                                  (item) => item !== plano.id
                                )
                              );
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`plano-${plano.id}`}
                        >
                          {plano.nome_plano}
                        </label>
                      </Col>
                    ) : (
                      <Col
                        md="12"
                        key={index}
                        className="form-check form-check-inline"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="planoCliente"
                          id={`plano-${plano.id}`}
                          value={plano.id}
                          checked={planosCliente.includes(plano.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPlanosCliente([plano.id]);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`plano-${plano.id}`}
                        >
                          {plano.nome_plano}
                        </label>
                      </Col>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </Col>
          </Row>

          {parseInt(painelTipo) === 1 && (
            <>
              <Row className="mt-5">
                <h5 className="card-title mb-4">URL da Lista</h5>
                <Col md="6">
                  <div>
                    <Label htmlFor="dominio" className="form-label">
                      Domínio
                    </Label>
                    <Input
                      type="select"
                      className="form-control"
                      id="dominio"
                      value={dominio}
                      onChange={(e) => {
                        setDominio(e.target.value);
                        setUrlOriginal(
                          `${e.target.value}get.php?username=${userDados?.externo_login}&password=${userDados?.externo_senha}&type=m3u_plus&output=m3u8`
                        );
                        setUrlEpg(
                          `${e.target.value}xmltv.php?username=${userDados?.externo_login}&password=${userDados?.externo_senha}&type=m3u_plus&output=m3u8`
                        );
                      }}
                      disabled={loading}
                    >
                      {dominios.map((dom) => (
                        <option key={dom.id} value={dom.dominio}>
                          {dom.dominio}
                        </option>
                      ))}
                    </Input>
                  </div>
                </Col>
                <Col md="6">
                  <div>
                    <Label htmlFor="tipo" className="form-label">
                      Tipo de URL
                    </Label>
                    <div className="input-group">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="tipo"
                          id="m3u8"
                          value="m3u8"
                          checked={tipo === "m3u8"}
                          onChange={() => {
                            alterarTipo("m3u8", userDados, dominio);
                          }}
                        />
                        <label className="form-check-label" htmlFor="m3u8">
                          M3U8
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="tipo"
                          id="ts"
                          value="ts"
                          checked={tipo === "ts"}
                          onChange={() => {
                            alterarTipo("ts", userDados, dominio);
                          }}
                        />
                        <label className="form-check-label" htmlFor="ts">
                          TS
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="tipo"
                          id="ssiptv"
                          value="ssiptv"
                          checked={tipo === "ssiptv"}
                          onChange={() => {
                            alterarTipo("ssiptv", userDados, dominio);
                          }}
                        />
                        <label className="form-check-label" htmlFor="ssiptv">
                          SSIPTV
                        </label>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md="6">
                  <div>
                    <Label htmlFor="url" className="form-label">
                      URL Original
                    </Label>
                    <div className="input-group">
                      <Input
                        type="text"
                        className="form-control"
                        id="url"
                        value={urlOriginal || "Carregando..."}
                        onChange={() => {}}
                        disabled
                      />
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => {
                          navigator.clipboard.writeText(urlOriginal);
                          toast.success("URL copiada com sucesso.");
                        }}
                        disabled={!urlOriginal || loading}
                      >
                        Copiar
                      </button>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div>
                    <Label htmlFor="urlEpg" className="form-label">
                      URL EPG
                    </Label>
                    <div className="input-group">
                      <Input
                        type="text"
                        className="form-control"
                        id="urlEpg"
                        value={urlEpg || "Carregando..."}
                        onChange={() => {}}
                        disabled
                      />
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => {
                          navigator.clipboard.writeText(urlEpg);
                          toast.success("URL copiada com sucesso.");
                        }}
                        disabled={!urlEpg || loading}
                      >
                        Copiar
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="6">
                  <div>
                    <Label htmlFor="urlEncurtada" className="form-label">
                      URL Encurtada
                    </Label>
                    <div className="input-group">
                      <Input
                        type="text"
                        className="form-control"
                        id="urlEncurtada"
                        value={urlEncurtada}
                        onChange={() => {}}
                        disabled
                      />
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => encurtar("url")}
                        disabled={loading || urlEncurtada || !urlOriginal}
                      >
                        {loading ? (
                          <Spinner style={{ width: "12px", height: "12px" }} />
                        ) : (
                          "Encurtar"
                        )}
                      </button>
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => {
                          navigator.clipboard.writeText(urlEncurtada);
                          toast.success("URL copiada com sucesso.");
                        }}
                        disabled={!urlEncurtada || loading}
                      >
                        Copiar
                      </button>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div>
                    <Label htmlFor="urlEncurtadaEpg" className="form-label">
                      URL EPG Encurtada
                    </Label>
                    <div className="input-group">
                      <Input
                        type="text"
                        className="form-control"
                        id="urlEncurtadaEpg"
                        value={urlEncurtadaEpg}
                        onChange={() => {}}
                        disabled
                      />
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => encurtar("epg")}
                        disabled={loading || urlEncurtadaEpg || !urlEpg}
                      >
                        {loading ? (
                          <Spinner style={{ width: "12px", height: "12px" }} />
                        ) : (
                          "Encurtar"
                        )}
                      </button>
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => {
                          navigator.clipboard.writeText(urlEncurtadaEpg);
                          toast.success("URL copiada com sucesso.");
                        }}
                        disabled={!urlEncurtadaEpg || loading}
                      >
                        Copiar
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}

          <Row className="mt-3">
            <div className="text-center mt-5">
              {idExterno === 0 ? (
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => criar(userDados)}
                  disabled={loading}
                >
                  Criar
                </Button>
              ) : (
                <>
                  <Button
                    type="button"
                    color="success"
                    onClick={() => editar(userDados)}
                    disabled={loading}
                  >
                    Atualizar
                  </Button>
                  <Button
                    className="ms-2"
                    type="button"
                    color="warning"
                    onClick={() => refazer(userDados)}
                    disabled={loading}
                  >
                    Refazer
                  </Button>
                </>
              )}
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default UnlimitedUser;
